import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "hidden" ]

  connect() {
    this.element.classList.remove(this.hiddenClass)
  }

  // This code seems unnecessary since the point of the controller is to hide things
  // when JavaScript is disabled
  // disconnect() {
  //   this.element.classList.add(this.hiddenClass)
  // }
}